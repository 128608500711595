import './Form.css';
import {Box, Card, CardContent, Checkbox, Divider, FormControlLabel, Grid, Stack, Typography} from "@mui/material";
import {useEffect, useReducer, useState} from "react";
import TotalScore from "./TotalScore";
import DetailScore from "./DetailScore";
import DetailComment from "./DetailComment";
import ClosureScore from "./ClosureScore";

function formCheck(form, initial) {
    if (!form) return false;
    for (const entry of Object.entries(initial)) {
        if (!form.hasOwnProperty(entry[0])) {
            return false;
        }
        if (typeof entry[1] === "object") {
            if (!formCheck(form[entry[0]], entry[1])) {
                return false;
            }
        }
    }
    return true;
}

function Form() {
    const [hasClosure, setHasClosure] = useState(false);
    const defaultForm = {
        startForm: {
            statScores: {
                referenceScore: 24,
                currentScore: 0,
                comprehensiveScore: 0,
                academicScore: 0,
            },
            commentScores: {
                word: 0,
                read: 0,
                content: 0,
                linguistic: 0,
            },
            comments: {
                word: "",
                read: "",
                content: "",
                linguistic: "",
            },
        },
    };
    const JSONForm = JSON.parse(localStorage.getItem("form"));
    const initialForm = formCheck(JSONForm, defaultForm) ? JSONForm : defaultForm;

    function formReducer(form, action) {
        switch (action.type) {
            case 'edit': {
                const block = {
                    ...form[action.form][action.block],
                    [action.key]: action.value
                };
                const f = {
                    ...form[action.form],
                    [action.block]: block
                };
                return {
                    ...form,
                    [action.form]: f
                }
            }
            case 'add': {
                let f = form.hasOwnProperty(action.form) ? form[action.form] : {};
                let b = f.hasOwnProperty(action.block) ? f[action.block] : {};
                b = {
                    ...b,
                    [action.key]: action.value
                }
                f = {
                    ...f,
                    [action.block]: b
                }
                return {
                    ...form,
                    [action.form]: f
                };
            }
            case 'remove': {
                delete form[action.form || action.block || action.key];
                return {
                    ...form
                };
            }
            default:
                console.error("Bad params in dispatch.", action.type, ...action);
        }
    }

    const [form, dispatch] = useReducer(formReducer, initialForm);

    useEffect(() => {
        localStorage.setItem("form", JSON.stringify(form));
    }, [form]);

    const And = (
        <Box
            component={"span"}
            sx={{
                display: 'inline-block', px: '4px', mx: '2px',
                transform: 'rotate(-30deg) scale(0.8)', borderRadius: '16%',
                backgroundColor: '#2c6760', color: '#fff'
            }}
        >
            &
        </Box>
    )

    return (
        <Box
            id={"form"}
            className={"form-wrapper"}
            elevation={3}
            component={"form"}
            sx={{
                '& .MuiTextField-root': { m: 1, width: '80%' },
            }}
            autoComplete={"off"}
        >
            <Card className={"form"}>
                <CardContent className={"form-header"}>
                    <Box className={"header-title-wrapper"}>
                        <Typography variant="h4" gutterBottom>
                            Scores{And}Comments
                        </Typography>
                    </Box>
                    <FormControlLabel control={
                        <Checkbox
                            checked={hasClosure}
                            onChange={(event, checked) => {
                                setHasClosure(checked);
                                if (checked) {
                                    dispatch({
                                        type: 'add',
                                        form: 'closureForm',
                                        block: 'scores',
                                        key: 'range',
                                        value: [0, 30],
                                    });
                                } else {
                                    dispatch({
                                        type: 'remove',
                                        form: 'closureForm',
                                    });
                                }
                            }}
                        />
                    }
                                      className={"form-checkbox-wrapper"}
                                      label={"结课报告"}
                    />
                </CardContent>
                <CardContent>
                    <Stack
                        direction="row"
                        className={"sub-form"}
                        justifyContent={"space-evenly"}
                        divider={<Divider orientation="vertical" flexItem />}
                    >
                        <TotalScore
                            label={"冲分班参考结课成绩"}
                            value={form.startForm.statScores.referenceScore}
                            dispatch={dispatch}
                            meta={{
                                type: 'edit',
                                form: 'startForm',
                                block: 'statScores',
                                key: 'referenceScore'
                            }}
                        />
                        <TotalScore
                            label={"学生目前写作分数"}
                            value={form.startForm.statScores.currentScore}
                            dispatch={dispatch}
                            meta={{
                                type: 'edit',
                                form: 'startForm',
                                block: 'statScores',
                                key: 'currentScore'
                            }}
                        />
                    </Stack>
                </CardContent>
                <CardContent>
                    <Grid container>
                        <DetailScore
                            className={"detail-score-wrapper comprehensive"}
                            label={"综合写作"}
                            value={form.startForm.statScores.comprehensiveScore}
                            dispatch={dispatch}
                            meta={{
                                type: 'edit',
                                form: 'startForm',
                                block: 'statScores',
                                key: 'comprehensiveScore'
                            }}
                        />
                        <DetailScore
                            className={"detail-score-wrapper academic"}
                            label={"学术讨论"}
                            value={form.startForm.statScores.academicScore}
                            dispatch={dispatch}
                            meta={{
                                type: 'edit',
                                form: 'startForm',
                                block: 'statScores',
                                key: 'academicScore'
                            }}
                        />
                    </Grid>
                </CardContent>
                <CardContent>
                    <Grid container>
                        <DetailComment
                            label={"遣词造句"}
                            score={form.startForm.commentScores.word}
                            comment={form.startForm.comments.word}
                            xs={12}
                            dispatch={dispatch}
                            meta={{
                                type: 'edit',
                                form: 'startForm',
                                block: 'comments',
                                key: 'word'
                            }}
                        />
                        <DetailComment
                            label={"阅读听力细节"}
                            score={form.startForm.commentScores.read}
                            comment={form.startForm.comments.read}
                            xs={12}
                            dispatch={dispatch}
                            meta={{
                                type: 'edit',
                                form: 'startForm',
                                block: 'comments',
                                key: 'read'
                            }}
                        />
                        <DetailComment
                            label={"内容维度"}
                            score={form.startForm.commentScores.content}
                            comment={form.startForm.comments.content}
                            xs={12}
                            dispatch={dispatch}
                            meta={{
                                type: 'edit',
                                form: 'startForm',
                                block: 'comments',
                                key: 'content'
                            }}
                        />
                        <DetailComment
                            label={"语言维度"}
                            score={form.startForm.commentScores.linguistic}
                            comment={form.startForm.comments.linguistic}
                            xs={12}
                            dispatch={dispatch}
                            meta={{
                                type: 'edit',
                                form: 'startForm',
                                block: 'comments',
                                key: 'linguistic'
                            }}
                        />
                    </Grid>
                </CardContent>
                <CardContent hidden={!hasClosure}>
                    <ClosureScore
                        label={"学生当前分数段"}
                        value={form.closureForm?.scores.range || []}
                        dispatch={dispatch}
                        meta={{
                            type: 'edit',
                            form: 'closureForm',
                            block: 'scores',
                            key: 'range'
                        }}
                    />
                </CardContent>
            </Card>
        </Box>
    );
}

export default Form;
