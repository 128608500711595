import './Form.css';
import {Input, Slider, Stack, Typography} from "@mui/material";

function TotalScore({label, value, dispatch, meta}) {
    const setValue = (newValue) => {
        dispatch({
            type: 'edit',
            form: meta['form'],
            block: meta['block'],
            key: meta['key'],
            value: newValue,
        });
    }
    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 30) {
            setValue(30);
        }
    };
    return (
        <Stack className={"total-score-wrapper"}>
            <Stack direction="row" spacing={2}>
                <Slider
                    value={typeof value === 'number' ? value : 0}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                    max={30}
                />
                <Input
                    className={"score-input"}
                    value={value}
                    size="small"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    inputProps={{
                        step: 5,
                        min: 0,
                        max: 30,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                    }}
                />
            </Stack>
            <Typography
                variant="caption" gutterBottom
                sx={{
                    boxShadow: "inset 0 -5px 0 0 rgba(44, 103, 96, 0.6)"
                }}
            >
                {label}
            </Typography>
        </Stack>
    );
}

export default TotalScore;
