import './ClosureComment.css';
import {Grid, Typography} from "@mui/material";
import mainIcon from "../../static/main-icon.png";
import '@fontsource/roboto/900.css';

function ClosureComment({scores}) {
    return (
        <Grid container className={"closure-report-comment-wrapper"} direction="column">
            <Grid item container className={"closure-report-comment-header"} direction="row">
                <Grid item xs={3} className={"header-icon-wrapper"}>
                    <img
                        src={mainIcon}
                        className={"main-icon"}
                        alt={"main-icon"}/>
                </Grid>
                <Grid item xs={6} className={"header-title-wrapper"}>
                    <Typography variant="h5" className={"header-title"}>
                        写作结课复习建议
                    </Typography>
                </Grid>
                <Grid item xs={3} className={"header-icon-wrapper"}>
                    <img
                        src={mainIcon}
                        className={"main-icon"}
                        alt={"main-icon"}/>
                </Grid>
            </Grid>
            <Grid item container className={"closure-report-comment-content"} direction="column">
                <Grid item className={"content-scores"}>
                    目前分数段：{scores.range[0]}-{scores.range[1]}
                </Grid>
                <Grid item container className={"content-comment"} direction="column">
                    <Grid item container className={"content-comment-section"} direction="column">
                        <Grid item className={"comment-section-header"}>
                            <Typography>综合写作</Typography>
                        </Grid>
                        <Grid item className={"comment-section-content"}>
                            <Typography>
                                <ul>
                                    <li>
                                        根据TPO真题进行练习。考前至少写10篇。按倒序，挑自己没做过的做即可。比如TPO 54→TPO 53→TPO 52。
                                    </li>
                                    <li>
                                        按正常考试流程做完综合写作之后，自己重听音频，再次尝试记录笔记。要比第一轮听的时候争取记下更多细节。然后回过头去检查自己的作文初稿，看有没有遗漏的听力细节。
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={"content-comment-section"} direction="column">
                        <Grid item className={"comment-section-header"}>
                            <Typography>学术讨论</Typography>
                        </Grid>
                        <Grid item className={"comment-section-content"}>
                            <Typography>
                                <ul>
                                    <li>
                                        复习上课老师所介绍的审题、理由构思技巧，让自己能够更加全面地回应题干信息。结合老师提供的真题进行大量的理由构思练习。争取30秒内完成宏观理由构思。
                                    </li>
                                    <li>
                                        在做托福阅读单项或者其它阅读的时候，尝试着从文章里提取实用句式，然后将这些书面化的句式运用到未来的写作中去，以此提升遣词造句的书面化程度。
                                    </li>
                                    <li>
                                        根据老师提供的写作题库，下次考前两到三天写一篇作文发给老师批改。然后参考老师的批注去修改一下语法错误和思路的错误。
                                    </li>
                                    <li>
                                        将我们讲解的例子和写作语言好好看看，积累不同领域的素材。
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container className={"content-review"}>
                    <Typography variant="h5" className={"review-title"}>
                        我的问题与复习策略：
                    </Typography>
                </Grid>

            </Grid>
            <Grid item container className={"closure-report-comment-signature"}>
                <Grid item className={"content-signature-wrapper"}>
                    <Typography variant="caption">
                        上海新东方国际教育托福®项目
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ClosureComment;
