import './Root.css';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {
    Backdrop,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    styled,
    TextField
} from "@mui/material";
import Article from '@mui/icons-material/Article';
import Grading from '@mui/icons-material/Grading';
import Delete from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Slideshow from '@mui/icons-material/Slideshow';
import html2canvas from "html2canvas";
import {useState} from "react";
import PptxGenJS from "pptxgenjs";

const powerpointProps = {
    filename: 'presentation',
    extension: '.pptx',
}

function Root() {
    const navigate = useNavigate();
    const StyledSpeedDial = styled(SpeedDial)(({theme}) => ({
        position: 'absolute',
        alignItems: 'flex-start',
        '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
            top: theme.spacing(2),
            left: theme.spacing(2),
        },
    }));

    const actionMap: { [index: string]: any } = {
        home: [
            {icon: <Article/>, name: '填写', path: `/form`},
        ],
        form: [
            {icon: <Grading/>, name: '生成', path: `/report`},
            {
                icon: <Delete/>, name: '清空', action: () => {
                    localStorage.removeItem("form");
                    navigate("/");
                }, path: `/form`
            },
        ],
        report: [
            {icon: <Article/>, name: '填写', path: `/form`},
            {icon: <PhotoCamera/>, name: '截图', action: () => screenshot(), path: `/capture`},
        ],
        capture: [
            {
                icon: <Article/>, name: '返回填写',
                action: () => localStorage.removeItem("capture"), path: `/form`
            },
            {
                icon: <Delete/>, name: '清空重填',
                action: () => {
                    localStorage.removeItem("capture");
                    localStorage.removeItem("form");
                }, path: `/form`
            },
            {
                icon: <Slideshow/>, name: '生成PPT',
                action: () => {
                    setDialogOpen(true);
                }
            },
        ],
    };

    const location = useLocation();
    const actions = actionMap[location.pathname.replace('/', '') || 'home'] || [];

    const [backdrop, setBackdrop] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <div id="root">
            <div id={"content-wrapper"}>
                <Outlet/>
            </div>
            <Backdrop open={backdrop}/>
            <div id={"dialer-wrapper"}>
                <StyledSpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{position: 'fixed', bottom: 16, right: 16}}
                    icon={<SpeedDialIcon/>}
                    direction={"right"}
                    open={backdrop}
                    onOpen={() => setBackdrop(true)}
                    onClose={() => setBackdrop(false)}
                >
                    {
                        actions.map((action) =>
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={async () => {
                                    if (action.action) await action.action();
                                    setBackdrop(false);
                                    if (action.path) {
                                        return navigate(action.path);
                                    }
                                }}
                            />
                        )
                    }
                </StyledSpeedDial>
            </div>
            <div id={"dialog-wrapper"}>
                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}
                        PaperProps={{
                            component: 'form',
                            onSubmit: (event) => {
                                event.preventDefault();
                                const formData = new FormData(event.currentTarget);
                                const formJSON = Object.fromEntries(formData.entries());
                                powerpointProps.filename = formJSON.filename || 'presentation';
                                console.log(powerpointProps);
                                generatePPT();
                                setDialogOpen(false);
                            },
                        }}
                >
                    <DialogTitle>Generate Powerpoint</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To generate the Powerpoint, please enter filename here, or default value will be taken.
                        </DialogContentText>
                        <TextField
                            margin={"dense"} id={"filename"} name={"filename"}
                            label={"Filename"} fullWidth variant={"standard"} placeholder={"presentation"}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
                        <Button type={"submit"}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}

const A4_SIZE_96PPI = {
    width: 794,
    height: 1123,
};

async function screenshot() {
    console.log("s")
    localStorage.removeItem("captures");
    const captures = [];
    const reports = ['start-report', 'closure-report'];
    for (const report of reports) {
        const element = document.querySelector("#".concat(report));
        if (element) {
            const dataURL = await html2canvas(element,
                {windowWidth: A4_SIZE_96PPI.width, windowHeight: A4_SIZE_96PPI.height}
            ).then(canvas => {
                return canvas.toDataURL();
            });
            captures.push(dataURL);
        }
    }
    localStorage.setItem("captures", JSON.stringify(captures));
    console.log("e")
}

function generatePPT() {
    const captures = JSON.parse(localStorage.getItem("captures"));
    let pptx = new PptxGenJS();
    pptx.defineLayout({
        name: 'A4',
        width: 8.268,
        height: 11.693,
    });
    pptx.layout = 'A4';

    captures.forEach(capture => {
        let slide = pptx.addSlide();
        slide.addImage({
            data: capture,
            w: '100%',
            h: '100%',
        })
    })
    pptx.writeFile({fileName: powerpointProps.filename.concat(powerpointProps.extension)})
        .then(() => console.log('success'))
        .catch(() => console.log('error'));
}

export default Root;
