import './StartStats.css';
import {Grid, Typography} from "@mui/material";
import SquareIcon from '@mui/icons-material/Square';

function StartStats({scores}) {
    return (
        <Grid container className={"Report-stats"} direction="column">
            <Grid item container className={"total-score score"}>
                <Grid item container className={"figure"}>
                    <Grid item container xs={10} className={"graph"}>
                        <Grid item container className={"axis"}>
                            <Grid item container className={"pod"}>
                                <Grid item className={"p"}>
                                    <Typography>0</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container className={"pod"}>
                                <Grid item className={"p"}>
                                    <Typography>5</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container className={"pod"}>
                                <Grid item className={"p"}>
                                    <Typography>10</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container className={"pod"}>
                                <Grid item className={"p"}>
                                    <Typography>15</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container className={"pod"}>
                                <Grid item className={"p"}>
                                    <Typography>20</Typography>
                                </Grid>
                            </Grid>
                            <Grid item className={"pod last"}>
                                <Grid item className={"p"}>
                                    <Typography>25</Typography>
                                </Grid>
                                <Grid item className={"end"}>
                                    <Typography>30</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container className={"chart"} direction="column">
                            <Grid item container className={"bar-wrapper"}>
                                <Grid item className={"bar-column reference"} width={scores.referenceScore/30}></Grid>
                            </Grid>
                            <Grid item container className={"bar-wrapper"}>
                                <Grid item className={"bar-column current"} width={scores.currentScore/30}></Grid>
                            </Grid>
                        </Grid>
                        <Grid item container className={"indicator-bar-container"} direction="column">
                            <Grid item container className={"indicator-bar"}>
                                <Grid item container className={"bar-column pseudo"} direction="column" width={scores.referenceScore/30}>
                                    <Typography className={"bar-label-wrapper"}>{scores.referenceScore}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container className={"indicator-bar"}>
                                <Grid item container className={"bar-column pseudo"} direction="column" width={scores.currentScore/30}>
                                    <Typography className={"bar-label-wrapper"}>{scores.currentScore}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} className={"label-wrapper"}>
                        <Typography className={"label"}>
                            总分对比
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} className={"legend"}>
                    <Grid item container xs={4} className={"legend-pod"}>
                        <SquareIcon fontSize="small" className={"squareIcon reference"}/>
                        <Typography>
                            冲分班参考结课成绩
                        </Typography>
                    </Grid>
                    <Grid item container xs={4} className={"legend-pod"}>
                        <SquareIcon fontSize="small" className={"squareIcon current"}/>
                        <Typography>
                            学生目前写作分数
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container className={"detail-score score"}>
                <Grid item container className={"figure"}>
                    <Grid item container xs={2} className={"detail-label-group"} direction="column">
                        <Grid item className={"detail-label"}>
                            <Typography>
                                综合写作
                            </Typography>
                        </Grid>
                        <Grid item className={"detail-label"}>
                            <Typography>
                                学术讨论
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={8} className={"graph"}>
                        <Grid item container className={"axis shrink"}>
                            <Grid item className={"big-pod pod"}>
                                <Grid item className={"p"}/>
                            </Grid>
                            <Grid item className={"big-pod pod"}>
                                <Grid item className={"p"}/>
                            </Grid>
                            <Grid item className={"big-pod pod"}>
                                <Grid item className={"p"}/>
                            </Grid>
                            <Grid item className={"big-pod pod"}>
                                <Grid item className={"p"}/>
                            </Grid>
                            <Grid item className={"big-pod pod last"}>
                                <Grid item className={"p"}/>
                            </Grid>
                        </Grid>
                        <Grid item container className={"chart"} direction="column">
                            <Grid item container className={"sub chart"} direction="column">
                                <Grid item container className={"bar-wrapper"}>
                                    <Grid item className={"bar-column reference"} width={5/5}></Grid>
                                </Grid>
                                <Grid item container className={"bar-wrapper"}>
                                    <Grid item className={"bar-column current"} width={scores.comprehensiveScore/5}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item container className={"sub chart"} direction="column">
                                <Grid item container className={"bar-wrapper"}>
                                    <Grid item className={"bar-column reference"} width={5/5}></Grid>
                                </Grid>
                                <Grid item container className={"bar-wrapper"}>
                                    <Grid item className={"bar-column current"} width={scores.academicScore/5}></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container className={"indicator-bar-container"} direction="column">
                            <Grid item container className={"sub indicator-bar-container"} direction="column">
                                <Grid item container className={"indicator-bar"}>
                                    <Grid item container className={"bar-column pseudo"} direction="column" width={5/5}>
                                        <Typography className={"bar-label-wrapper"}>{5}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container className={"indicator-bar"}>
                                    <Grid item container className={"bar-column pseudo"} direction="column" width={scores.comprehensiveScore/5}>
                                        <Typography className={"bar-label-wrapper"}>{scores.comprehensiveScore}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container className={"sub indicator-bar-container"} direction="column">
                                <Grid item container className={"indicator-bar"}>
                                    <Grid item container className={"bar-column pseudo"} direction="column" width={5/5}>
                                        <Typography className={"bar-label-wrapper"}>{5}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container className={"indicator-bar"}>
                                    <Grid item container className={"bar-column pseudo"} direction="column" width={scores.academicScore/5}>
                                        <Typography className={"bar-label-wrapper"}>{scores.academicScore}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} className={"label-wrapper"}>
                        <Typography className={"label"}>
                            小分对比
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container spacing={4} className={"legend"}>
                    <Grid item container xs={2} className={"legend-pod"}>
                        <SquareIcon fontSize="small" className={"squareIcon reference"}/>
                        <Typography>
                            满分
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={"note"}>
                <Typography>
                    注：2022年全球托福考生写作均分21.7分 2022年中国考生托福写作均分22分
                </Typography>
            </Grid>
        </Grid>
    );
}

export default StartStats;
