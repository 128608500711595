import './StartHeader.css';
import mainIcon from '../../static/main-icon.png'
import {Grid} from "@mui/material";

function StartHeader() {
    return (
        <Grid container className={"Report-header"}>
            <Grid item xs={4} className={"header-icon-wrapper"}>
                <img
                    src={mainIcon}
                    className={"main-icon"}
                    alt={"main-icon"}
                />
            </Grid>
            <Grid item xs={8} container direction="column">
                <Grid item className={"main-title"}>WRITING SECTION</Grid>
                <Grid item className={"sub-title"}>写作部分模考分析&反馈</Grid>
            </Grid>
        </Grid>
    );
}

export default StartHeader;
