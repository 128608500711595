import './StartReport.css';
import {Box} from "@mui/material";
import StartStats from "./StartStats";
import StartHeader from "./StartHeader";
import StartComment from "./StartComment";

function StartReport({startForm}) {
    return (
        <Box className={"start-report"} id={"start-report"}>
            <StartHeader/>
            <StartStats scores={startForm.statScores}/>
            <StartComment scores={startForm.commentScores} comments={startForm.comments}/>
        </Box>
    );
}

export default StartReport;
