import './StartComment.css';
import {createTheme, Grid, ThemeProvider, Typography} from "@mui/material";
import comprehensive from "../../static/comprehensive.png";
import academic from "../../static/academic.png";
import words from "../../static/comment-words.png";
import read from "../../static/comment-read.png";
import content from "../../static/comment-content.png";
import linguistic from "../../static/comment-linguistic.png";
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

function StartComment({scores, comments}) {
    const commentTextTheme = createTheme();

    commentTextTheme.typography.body2 = {
        fontSize: '0.6rem',
        '@media (min-width:771px)': {
            fontSize: '1.2rem',
        },
        [commentTextTheme.breakpoints.up('lg')]: {
            fontSize: '2.8rem',
        },
    };

    return (
        <Grid container className={"Report-comment"} direction="column">
            <Grid item container className={"content-group"} direction="row">
                <Grid item xs={6} className={"content-wrapper"}>
                    <Grid container className={"content comprehensive"} direction="column">
                        <Grid item container className={"content-header"}>
                            <Grid item xs={2} className={"content-header-icon-wrapper"}>
                                <img
                                    src={comprehensive}
                                    className={"content-header-icon"}
                                    alt={"comprehensive"}
                                />
                            </Grid>
                            <Grid item xs={8} className={"content-header-title-wrapper"}>
                                <Typography variant="h5" className={"content-header-title"}>
                                    综合写作题
                                </Typography>
                            </Grid>
                            <Grid item xs={2} className={"content-header-icon-wrapper"}>
                                <img
                                    src={comprehensive}
                                    className={"content-header-icon"}
                                    alt={"comprehensive"}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container className={"content-box"} flexDirection="column">
                            <Grid item container className={"content-box-title"}>
                                <Grid item xs={3} className={"content-box-icon-wrapper"}>
                                    <img
                                        src={words}
                                        className={"content-icon"}
                                        alt={"words"}
                                    />
                                </Grid>
                                <Grid item xs={6} className={"content-box-text-wrapper"}>
                                    <Typography className={"content-box-text"}>
                                        遣词造句
                                    </Typography>
                                </Grid>
                                <Grid item container xs={3}>
                                    <Grid item>{scores.word}</Grid>
                                    <Grid item>/</Grid>
                                    <Grid item>5</Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={"content-box-body"}>
                                <ThemeProvider theme={commentTextTheme}>
                                    <Typography variant="body2">
                                        {comments.word}
                                    </Typography>
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                        <Grid item container className={"content-box"} flexDirection="column">
                            <Grid item container className={"content-box-title"}>
                                <Grid item xs={3} className={"content-box-icon-wrapper"}>
                                    <img
                                        src={read}
                                        className={"content-icon"}
                                        alt={"read"}
                                    />
                                </Grid>
                                <Grid item xs={6} className={"content-box-text-wrapper"}>
                                    <Typography className={"content-box-text"}>
                                        阅读听力细节
                                    </Typography>
                                </Grid>
                                <Grid item container xs={3}>
                                    <Grid item>{scores.read}</Grid>
                                    <Grid item>/</Grid>
                                    <Grid item>5</Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={"content-box-body"}>
                                <ThemeProvider theme={commentTextTheme}>
                                    <Typography variant="body2">
                                        {comments.read}
                                    </Typography>
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} className={"content-wrapper"}>
                    <Grid container className={"content academic"} direction="column">
                        <Grid item container className={"content-header"}>
                            <Grid item xs={2} className={"content-header-icon-wrapper"}>
                                <img
                                    src={academic}
                                    className={"content-header-icon"}
                                    alt={"academic"}
                                />
                            </Grid>
                            <Grid item xs={8} className={"content-header-title-wrapper"}>
                                <Typography variant="h5" className={"content-header-title"}>
                                    学术讨论题
                                </Typography>
                            </Grid>
                            <Grid item xs={2} className={"content-header-icon-wrapper"}>
                                <img
                                    src={academic}
                                    className={"content-header-icon"}
                                    alt={"academic"}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container className={"content-box"} flexDirection="column">
                            <Grid item container className={"content-box-title"}>
                                <Grid item xs={3} className={"content-box-icon-wrapper"}>
                                    <img
                                        src={content}
                                        className={"content-icon"}
                                        alt={"content"}
                                    />
                                </Grid>
                                <Grid item xs={6} className={"content-box-text-wrapper"}>
                                    <Typography className={"content-box-text"}>
                                        内容维度
                                    </Typography>
                                </Grid>
                                <Grid item container xs={3}>
                                    <Grid item>{scores.content}</Grid>
                                    <Grid item>/</Grid>
                                    <Grid item>5</Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={"content-box-body"}>
                                <ThemeProvider theme={commentTextTheme}>
                                    <Typography variant="body2">
                                        {comments.content}
                                    </Typography>
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                        <Grid item container className={"content-box"} flexDirection="column">
                            <Grid item container className={"content-box-title"}>
                                <Grid item xs={3} className={"content-box-icon-wrapper"}>
                                    <img
                                        src={linguistic}
                                        className={"content-icon"}
                                        alt={"linguistic"}
                                    />
                                </Grid>
                                <Grid item xs={6} className={"content-box-text-wrapper"}>
                                    <Typography className={"content-box-text"}>
                                        语言维度
                                    </Typography>
                                </Grid>
                                <Grid item container xs={3}>
                                    <Grid item>{scores.linguistic}</Grid>
                                    <Grid item>/</Grid>
                                    <Grid item>5</Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={"content-box-body"}>
                                <ThemeProvider theme={commentTextTheme}>
                                    <Typography variant="body2">
                                        {comments.linguistic}
                                    </Typography>
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container className={"content-signature"}>
                <Grid item className={"content-signature-wrapper"}>
                    <Typography variant="caption">
                        上海新东方国际教育托福®项目
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default StartComment;
