import './Form.css';
import {Grid, Rating, Typography} from "@mui/material";

function DetailScore({label, value, xs, dispatch, meta, className}) {
    const setValue = (newValue) => {
        dispatch({
            type: 'edit',
            form: meta['form'],
            block: meta['block'],
            key: meta['key'],
            value: newValue,
        });
    }
    return (
        <Grid item container xs={xs} className={className}>
            <Grid item xs={4}>
                <Typography>
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Rating
                    value={typeof value === "number" ? value : 0}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default DetailScore;
