import './Report.css';
import {Box} from "@mui/material";
import ClosureReport from "./closure/ClosureReport";
import StartReport from "./start/StartReport";
import {ReactElement} from "react";

function Report() {
    const form = JSON.parse(localStorage.getItem("form"));
    const reportMap: { [index: string]: ReactElement } = {
        startForm: <StartReport startForm={form.startForm}/>,
        closureForm: <ClosureReport closureForm={form.closureForm}/>,
    }
    const reports: { [index: string]: any } = Object.entries(form).filter(entry => Object.keys(entry[1]).length !== 0);
    return (
        <Box className={"report"} id={"report"}>
            {
                reports.map(report =>
                    <div key={report[0]}>
                        {reportMap[report[0]]}
                    </div>
                )
            }
        </Box>
    );
}

export default Report;
