import './Capture.css';
import {Box, Grow, Snackbar} from "@mui/material";
import {useState} from "react";

function Capture() {
    const captures = JSON.parse(localStorage.getItem("captures")) || [];
    const [tipVisible, setTipVisible] = useState(true);
    const handleClose = () => {
        setTipVisible(false);
    }
    return (
        <Box className={"capture-wrapper"}>
            <Box
                className={"tip-wrapper"}
            >
                <Snackbar
                    open={tipVisible}
                    message={"Long press to save screenshot."}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    onClose={handleClose}
                    TransitionComponent={Grow}
                />
            </Box>
            {
                captures.map(capture =>
                    <img
                        key={capture}
                        className={"capture"}
                        src={capture}
                        alt={"report-screenshot"}
                    />
                )
            }
        </Box>
    );
}

export default Capture;
