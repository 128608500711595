import './ClosureHeader.css';
import HeaderImg from '../../static/closure-report-header-img.png'
import {Grid} from "@mui/material";

function ClosureHeader() {
    return (
        <Grid container className={"closure-report-header"} direction={"column"}>
            <Grid item xs={7} className={"closure-report-header-head"} container direction="column">
                <Grid item className={"main-title"}>后续备考建议及复习规划</Grid>
                <Grid item className={"sub-title"}>Review Plan</Grid>
            </Grid>
            <Grid item xs={5} className={"closure-report-header-tail"}>
            </Grid>
            <Grid className={"closure-report-header-img-wrapper"}>
                <img
                    src={HeaderImg}
                    className={"closure-report-header-img"}
                    alt={"closure-report-header-img"}
                />
            </Grid>
        </Grid>
    );
}

export default ClosureHeader;
