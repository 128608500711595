import './ClosureReport.css';
import {Box} from "@mui/material";
import ClosureHeader from "./ClosureHeader";
import ClosureComment from "./ClosureComment";

function ClosureReport({closureForm}) {
    return (
        <Box className={"closure-report"} id={"closure-report"}>
            <ClosureHeader/>
            <ClosureComment scores={closureForm.scores}/>
        </Box>
    );
}

export default ClosureReport;
