import './DetailComment.css';
import {Grid, TextField} from "@mui/material";
import DetailScore from "./DetailScore";

function DetailComment({label, score, xs, comment, dispatch, meta}) {
    const setValue = (newValue) => {
        dispatch({
            type: 'edit',
            form: meta['form'],
            block: meta['block'],
            key: meta['key'],
            value: newValue,
        });
    }
    return (
        <Grid item container
              direction="column"
              xs={xs} className={"detail-comment-wrapper"}
              spacing={2}
        >
            <DetailScore
                label={label}
                value={score}
                dispatch={dispatch}
                meta={{
                    type: 'edit',
                    form: meta['form'],
                    block: 'commentScores',
                    key: meta['key']
                }}
            />
            <Grid item className={"detail-comment-text-wrapper"}>
                <TextField
                    label={label}
                    multiline
                    minRows={6}
                    maxRows={10}
                    fullWidth
                    value={comment}
                    onChange={(event) => {
                        console.log(event.target.value);
                        setValue(event.target.value);
                    }}
                >
                </TextField>
            </Grid>
        </Grid>
    );
}

export default DetailComment;
