import './Form.css';
import {Slider, Stack, Typography} from "@mui/material";

function ClosureScore({label, value, dispatch, meta}) {
    const setValue = (newValue) => {
        dispatch({
            type: 'edit',
            form: meta['form'],
            block: meta['block'],
            key: meta['key'],
            value: newValue,
        });
    }
    const minDistance = 1;
    const handleSliderChange = (event, newValue, activeThumb) => {
        if (activeThumb === 0) {
            setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
        } else {
            setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
        }
    };
    const distance = value[1] - value[0];
    const style = distance <= 3 ? 100 + (4 - distance) * 40 : 100;
    return (
        <Stack className={"closure-score-wrapper"}>
            <Typography gutterBottom>
                {label}
            </Typography>
            <Slider
                sx={{
                    color: '#52af77',
                    height: 10,
                    '& .MuiSlider-track': {
                        border: 'none',
                    },
                    '& .MuiSlider-thumb': {
                        transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                        height: 24,
                        width: 24,
                        backgroundColor: '#fff',
                        border: '2px solid currentColor',
                        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                            boxShadow: 'inherit',
                        },
                        '&::before': {
                            display: 'none',
                        },
                        '&[data-index="1"] span.MuiSlider-valueLabelOpen': {
                            transform: `translate(50%, -${style}%) rotate(-45deg) scale(1)`,
                        },
                    },
                    '& .MuiSlider-valueLabel': {
                        lineHeight: 1.2,
                        fontSize: 12,
                        background: 'unset',
                        padding: 0,
                        width: 32,
                        height: 32,
                        borderRadius: '50% 50% 50% 0',
                        backgroundColor: '#52af77',
                        transformOrigin: 'bottom left',
                        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
                        '&::before': { display: 'none' },
                        '&.MuiSlider-valueLabelOpen': {
                            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
                        },
                        '& > *': {
                            transform: 'rotate(45deg)',
                        },
                    },
                }}
                value={value}
                onChange={handleSliderChange}
                aria-labelledby="scores-slider"
                valueLabelDisplay={"on"}
                disableSwap
                max={30}
            />
        </Stack>
    );
}

export default ClosureScore;
